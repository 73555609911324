// Override Bootstrap's Sass default variables
//
// Place variable overrides first, then import just the styles you need.
// Note that some stylesheets are required no matter what.

// Include functions first
@import '../../node_modules/bootstrap/scss/functions';

// Create your own map
$primary: #0ad3ff;
$secondary: #615d6c;
$success: #5dfdcb;
$info: #ff608a;
$warning: #f9f45e;
$danger: #663c8e;
$light: #f7f7ff;
$dark: #1a1d2b;
$min-contrast-ratio: 2;
// $color-scheme: (
//   'theme1': black,
//   'theme2': yellow,
// );

// Required
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';

// Merge the maps
// $theme-colors: map-merge($color-scheme, $theme-colors);

// Bootstrap main import
@import '../../node_modules/bootstrap/scss/bootstrap';

// Custom theme
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Rubik:wght@400;700&display=swap');

body {
  font-family: 'Noto Sans', sans-serif;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: 'Rubik', sans-serif;
}

.btn {
  font-family: 'Rubik', sans-serif;
}
